import React from 'react'
import Layout from "../components/layout"
import { Card, Container, ListGroup } from "react-bootstrap"


export default function terms() {
  return (
    <Layout>
      <Container>
        <Card>
          <Card.Body className="temsBody">
            <Card.Title className="termsTitle">Terms and Conditions</Card.Title>
            <br></br>
            <Card.Subtitle className="termsSubTitle">
              Welcome to LOGOPWA!
            </Card.Subtitle>
            <br></br>
            <Card.Subtitle className="termsSubTitle">
              LOGOPWA is owned and operated by LOGOPWA, INC.
            </Card.Subtitle>
            <Card.Text className="termsText">
              These are the terms and conditions for: https://www.logopwa.com
              <br></br> By accessing the LOGOPWA website and using the services,
              you agree to be bound by these terms and conditions and our
              Privacy Policy. If you do not accept all of these Terms, then you
              may not use Our Website and services. In these Terms, "we", "us",
              "our" and " LOGOPWA" refers to LOGOPWA and "you" and “your" refers
              to you, the user of our website and services. PLEASE READ THESE
              TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING, USING OR
              OBTAINING ANY MATERIALS, INFORMATION OR SERVICES.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              ACCEPTANCE OF TERMS
            </Card.Subtitle>
            <Card.Text className="termsText">
              This agreement sets forth legally binding terms for your use of
              LOGOPWA. By using LOGOPWA, you agree to be bound by this
              agreement. If you do not accept the terms of this agreement, you
              should leave LOGOPWA website immediately. We may modify this
              agreement from time to time, and such modification shall be
              effective upon its posting on LOGOPWA. You agree to be bound by
              any modification to this agreement when you use LOGOPWA after any
              such modification is posted; it is therefore important that you
              review this agreement regularly. You represent and warrant that
              all registration information you submit is accurate and truthful;
              and that your use of the services does not violate any applicable
              law or regulation. LOGOPWA may, in its sole discretion, refuse to
              offer the platform to any entity and change its eligibility
              criteria at any time. This provision is void where prohibited by
              law and the right to access the Service and the platform is
              revoked in such jurisdictions.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">ELIGIBLITY</Card.Subtitle>
            <Card.Text className="termsText">
              You may use the website and services only if you can form a
              binding contract with LOGOPWA, and only in compliance with these
              terms and all applicable local, state, national, and international
              laws, rules, and regulations. The use of this website and services
              for children under 13 years is prohibited and older children
              should be supervised and given appropriate guidance in their use
              of our website and services. It is the responsibility of parents
              and legal guardians to determine whether any of the content and/or
              services are appropriate for their child. By using the services,
              you represent and warrant that you have the full right, power and
              authority to enter into this agreement and to fully perform all of
              your obligations hereunder. You further represent and warrant that
              you are under no legal disability or contractual restriction that
              prevents you from entering into this agreement.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">LOGOPWA</Card.Subtitle>
            <Card.Text className="termsText">
              Welcome to LOGOPWA! We are an online graphic design, web
              programming, and app development agency specializes in custom logo
              design, web application, web design through the use of Programming
              Web Application (PWA) technologies, and other unique tools that
              make our clients business ideas a reality. LOGOPWA offers through
              this website the following services:
            </Card.Text>
            <ListGroup variant="flush">
              <ListGroup.Item> • Logo Design </ListGroup.Item>
              <ListGroup.Item>• Web Design</ListGroup.Item>
              <ListGroup.Item>• Web Development</ListGroup.Item>
              <ListGroup.Item>• Digital marketing</ListGroup.Item>
            </ListGroup>
            <br></br>
            <Card.Text className="termsText">
              You can also contact us through our contact information, our
              support team will be attentive and available to answer your
              questions and concerns at info@logopwa.com.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              HOW IT WORKS
            </Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>
                1. Get a free consultation here:
                <Card.Link href="https://calendly.com/logopwa?month=2020-07">
                  Reserve consultation
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item>
                2. Buy a service package (logo design, web design, etc.)
              </ListGroup.Item>
              <ListGroup.Item>
                3. Prepare to get concepts: In just three days, client will
                received logo concepts or website prototype for feedback and
                revision.
              </ListGroup.Item>
            </ListGroup>
            <br></br>

            <Card.Subtitle className="termsSubTitle">Services</Card.Subtitle>
            <Card.Text className="termsText">
              When you place an order for a package, you are offering to buy the
              service for the advertised price declared on the website, in
              accordance with the features offered on LOGOPWA. Please check the
              price and features of each package, before placing an order.
            </Card.Text>
            <Card.Text className="termsText">
              When a website user, places an order online, LOGOPWA will send
              that user an email to confirm that the order has been received.
              This email confirmation will be produced automatically so that the
              user has confirmation of their package details.
            </Card.Text>
            <Card.Text className="termsText">
              LOGOPWA works on a project upon receipt of down payment, package
              purchase, or full payment. the client is charged the remaining
              balance upon completion of the project. Websites are made live
              upon completion of the project, when all payments are cleared.
              Now, hosting and domain name registration fees must be paid every
              month.
            </Card.Text>
            <Card.Text className="termsText">
              Any delays on project completion may occur if there is a delay in
              payment from client, changes to project, delay of client to
              provide materials or resources, or client request additional
              services related to project.
            </Card.Text>
            <Card.Text className="termsText">
              Additional services as requested per client comes with additional
              fees, on a fee for services or on an hourly rate. Any additional
              fee charge for additional services as requested by client will be
              added to their invoice and charge accordingly.
            </Card.Text>
            <Card.Text className="termsText">
              Revision cycles. Revisions are design to created interactive
              responses between the client and designer that moves the project
              forward to the specific ideal concept that is in mind. Please note
              that significant changes can happen during the first, and second
              revision. The third revision should have modification from the
              current object. Any additional request of changes after third
              revision can be honor and modify in a timely manner but LOGOPWA
              reserves the right to charge a $50 dollars additional fee per
              extra revision.
            </Card.Text>
            <Card.Text className="termsText">
              LOGOPWA may cancel any sale and not supply services if it is
              reasonable to do so and may change or discontinue the availability
              of the services at any time at its sole discretion. This does not
              affect your Statutory Rights.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">PRICES</Card.Subtitle>
            <Card.Text className="termsText">
              LOGOPWA reserves the right to determine service prices. LOGOPWA
              will make reasonable efforts to keep pricing information published
              on the website up to date. We encourage you to check our website
              periodically for current pricing information. LOGOPWA may change
              the fees for any package, including additional fees or charges.
              LOGOPWA, at its sole discretion, may make promotional offers with
              different features and different pricing to any of LOGOPWA
              customers.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">PAYMENTS</Card.Subtitle>
            <Card.Text className="termsText">
              Your payment information will be treated and safeguarded with
              total security and with the exclusive purpose of processing
              payment for the plans and services. LOGOPWA Inc reserves the right
              to contract any payment platform available in the market, which
              treats your data for the exclusive purpose of processing payment
              for plans and services. Payment will be debited from your credit
              card or debit card immediately on you placing the order for the
              service you have purchased. Upon processing a transaction, we will
              issue you with an electronic transaction receipt which will be
              sent to the email address you provide.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              THIRD-PARTY MATERIALS.
            </Card.Subtitle>
            <Card.Text className="termsText">
              “Third-Party Materials” means any content, images, videos, texts
              or other material that is owned by a third party, such as stock
              images, videos and texts. Such Third-Party Materials are subject
              to the applicable third-party terms, and licenses. This may only
              be used as permitted by such terms and licenses.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              COPYRIGHT OF THE WORKS
            </Card.Subtitle>
            <Card.Text className="termsText">
              All rights, titles and interests in all original creations
              prepared by LOGOPWA for the customer in connection with this terms
              and services will be transferred and assigned once the customer
              makes full payment for the services and the final works are
              delivered. The customer will own all rights to the final works
              delivered by LOGOPWA.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              GENERAL COPYRIGHT
            </Card.Subtitle>
            <Card.Text className="termsText">
              All content included on this website, such as text, graphics,
              logos, button icons, images, video, audio clips, data compilation,
              and software, is the property of LOGOPWA, its merchants, or its
              content suppliers and protected by international copyright laws.
              The compilation of all content on this site is the exclusive
              property of LOGOPWA and protected by international copyright laws.
              All software used on this site is the property of LOGOPWA or its
              software suppliers and protected by international copyright laws.
              You have no authorization to copy, transmit, distribute, display,
              republish, post, or upload from our website in any way without our
              prior written approval, or stated otherwise on our site. You may
              print a copy of our site's content strictly for personal use only.
              By doing so, you also consent not to directly or indirectly change
              or remove any copyright, trade name, service mark, trademark, or
              any other proprietaries shown on any of our content. Any
              alterations or use of content outside the guidelines of this Terms
              and Conditions violates intellectual property rights. By accessing
              our website, you do not own any rights or titles to our content or
              other intellectual properties.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              COPYRIGHT COMPLAINTS
            </Card.Subtitle>
            <Card.Text className="termsText">
              LOGOPWA respects the intellectual property of others and expects
              users to do the same. If you believe, in good faith, that any
              materials provided on or in connection with the LOGOPWA website
              infringe upon your copyright or other intellectual property right,
              please send the following information to:<br></br> i.
              Identification of the intellectual property right that is
              allegedly infringed. All relevant registration numbers, or a
              statement concerning the ownership of the work, should be
              included.<br></br> ii. A statement specifically identifying the
              location of the infringing material, with enough detail that
              LOGOPWA may find it on the LOGOPWA Site. Please note: it is not
              sufficient to merely provide a top-level URL.<br></br> iii. Your
              name, address, telephone number and e-mail address.<br></br> iv. A
              statement by you that you have a good faith belief that the use of
              the allegedly infringing material is not authorized by the owner
              of the rights, or its agents, or by law.<br></br> v. A statement
              by you, made under penalty of perjury, that the information in
              your notice is accurate, and that you are the copyright owner or
              authorized to act on the copyright owner's behalf.<br></br> vi. An
              electronic or physical signature of the owner of the copyright or
              the person authorized to act on behalf of the owner of the
              copyright interest.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              RESALE OF SERVICES
            </Card.Subtitle>
            <Card.Text className="termsText">
              Users may not resell the services to any other party without prior
              written permission from LOGOPWA, which may be granted or withheld
              in LOGOPWA sole discretion.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              ACCURACY AND TIMELINESS OF INFORMATION
            </Card.Subtitle>
            <Card.Text className="termsText">
              We do not guarantee that the information available on the website
              is accurate, complete or updated. Any use of the material provided
              on this website is at your own risk.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              PROHIBITED ACTIVITIES
            </Card.Subtitle>
            <Card.Text className="termsText">
              The content and information available on the website (including,
              but not limited to, data, information, text, music, sound, photos,
              graphics, video, maps, icons or other material), as well as the
              infrastructure used to provide such Content and information, is
              proprietary to LOGOPWA or licensed to the LOGOPWA by third
              parties. For all content other than your content, you agree not to
              otherwise modify, copy, distribute, transmit, display, perform,
              reproduce, publish, license, create derivative works from,
              transfer, or sell or re-sell any information, software or services
              obtained from or through the website. Additionally, you agree not
              to:
              <br></br>
              i. Use the services or content for any commercial purpose, outside
              the scope of those commercial purposes explicitly permitted under
              this agreement and related guidelines as made available by
              LOGOPWA;
              <br></br>
              ii. Access, monitor, reproduce, distribute, transmit, broadcast,
              display, sell, license, copy or otherwise exploit any Content of
              the Services, including but not limited to, using any robot,
              spider, scraper or other automated means or any manual process for
              any purpose not in accordance with this agreement or without our
              express written permission;
              <br></br>
              iii. Violate the restrictions in any robot exclusion headers on
              the Services or bypass or circumvent other measures employed to
              prevent or limit access to the Services;
              <br></br>
              iv. Take any action that imposes, or may impose, in our
              discretion, an unreasonable or disproportionately large load on
              our infrastructure;
              <br></br>
              v. Deep-link to any portion of the Services for any purpose
              without our express written permission;
              <br></br>
              vi. "Frame", "mirror" or otherwise incorporate any part of the
              services into any other websites or service without our prior
              written authorization;
              <br></br>
              vii. Attempt to modify, translate, adapt, edit, decompile,
              disassemble, or reverse engineer any software programs used by
              LOGOPWA in connection with the services;
              <br></br>
              viii. Circumvent, disable or otherwise interfere with
              security-related features of the Services or features that prevent
              or restrict use or copying of any Content; or
              <br></br>
              ix. Download any Content unless it’s expressly made available for
              download by LOGOPWA.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              DISCLAIMER OF WARRANTIES
            </Card.Subtitle>
            <Card.Text className="termsText">
              LOGOPWA will provide its services with reasonable skill and care
              but does not give any guarantees, warranties or representations in
              respect of any other person's services.<br></br>
              <br></br>
              Because of the nature of the Internet LOGOPWA provides and
              maintains the website on an "as is", "as available" basis and
              makes no promise that use of The Website will be uninterrupted or
              entirely error free. We are not responsible to you if we are
              unable to provide our Internet services for any reason beyond our
              control.<br></br>
              <br></br>
              Our website may from time to time contain links to other web sites
              which are not under the control of and are not maintained by us.
              These links are provided for your convenience only and we are not
              responsible for the content of those sites.<br></br>
              <br></br>
              You will be responsible for any breach of these terms by you and
              if you use The Website in breach of these terms you will be liable
              to and will reimburse LOGOPWA for any loss or damage caused as a
              result.<br></br>
              <br></br>
              LOGOPWA will not be liable in any amount for failure to perform
              any obligation under this agreement if such failure is caused by
              the occurrence of any unforeseen event beyond its reasonable
              control including without limitation Internet outages,
              communications outages, fire, flood, war or act of God.<br></br>
              <br></br>
              These terms do not affect your statutory rights as a consumer
              which are available to you.<br></br>
              <br></br>
              Subject as aforesaid, to the maximum extent permitted by law,
              LOGOPWA excludes liability for any loss or damage of any kind
              howsoever arising, including without limitation any direct,
              indirect or consequential loss whether or not such arises out of
              any problem you notify to LOGOPWA and LOGOPWA shall have no
              liability to pay any money by way of compensation, including
              without limitation all liability in relation to:<br></br>
              <br></br>• Any interruptions to or delays in updating LOGOPWA;
              <br></br>• The infringement by any person of any Intellectual
              Property Rights of any third party caused by their use of The
              Website or any product or service purchased through the website;
              <br></br>• Any loss or damage resulting from your use or the
              inability to use the web site or resulting from unauthorized
              access to, or alteration of your transmissions or data in
              circumstances which are beyond our control;<br></br>• Any loss of
              profit, wasted expenditure, corruption or destruction of data or
              any other loss which does not directly result from something we
              have done wrong;<br></br>• Any amount or kind of loss or damage
              due to viruses or other malicious software that may infect a
              user's computer equipment, software, data or other property caused
              by persons accessing or using content from The Website, or from
              transmissions via emails or attachments received from LOGOPWA.
              <br></br>• All representations, warranties, conditions and other
              terms which but for this notice would have effect.<br></br>
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              LOGOPWA AND THIRD PARTIES
            </Card.Subtitle>
            <Card.Text className="termsText">
              LOGOPWA contains Content of third-party licensors to LOGOPWA,
              which is protected by copyright, trademark, patent, trade secret
              and other laws. LOGOPWA owns and retains all rights, title and
              interest in the Content. LOGOPWA hereby grants to you a limited,
              revocable, non-sublicensable license to Stream and/or view the
              Content and any third party Content located on or available
              through LOGOPWA or Service (excluding any software code therein as
              set forth above) solely for your personal, non-commercial use in
              connection with viewing LOGOPWA and using the Service.<br></br>
              <br></br>
              Any dealings with third parties included within or on LOGOPWA
              involving the delivery of and payment for goods and services, or
              any other terms, conditions, warranties, or representations
              associated with such third parties, are solely between you and
              that third party. LOGOPWA is neither responsible nor liable for
              any part of such dealings or promotions. Any reference to any
              third party or the products or services of any third party on
              LOGOPWA does not constitute an endorsement or recommendation of
              such third party or the product or services of such third party by
              LOGOPWA or any of its employees, officers, independent
              contractors, agents or other representatives. Any reference to any
              third party on LOGOPWA is provided to you for informational
              purposes only. LOGOPWA encourages you to conduct your own research
              and due diligence regarding such third parties and their products
              and services. While LOGOPWA works to ensure the information on
              LOGOPWA is current and accurate.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              NON-COMMERCIAL USE
            </Card.Subtitle>
            <Card.Text className="termsText">
              The services and the website may not be used in connection with
              any commercial purposes, except as specifically approved by
              LOGOPWA. Unauthorized framing of or linking to any of LOGOPWA is
              prohibited. Commercial advertisements, affiliate links, and other
              forms of content without notice and may result in termination of
              usership privileges.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              SECURITY COMPONENTS
            </Card.Subtitle>
            <Card.Text className="termsText">
              You understand that LOGOPWA and software embodied within LOGOPWA
              may include security components that permit digital materials to
              be protected, and that use of these materials is subject to usage
              rules set by LOGOPWA and/or content providers who provide content
              to LOGOPWA platform. You may not attempt to override, disable,
              circumvent or otherwise interfere with any such security
              components and usage rules embedded into LOGOPWA.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              ELECTRONIC COMMUNICATIONS
            </Card.Subtitle>
            <Card.Text className="termsText">
              No responsibility will be accepted by LOGOPWA for failed, partial
              or garbled computer transmissions, for any computer, telephone,
              cable, network, electronic or internet hardware or software
              malfunctions, failures, connections, availability, for the acts or
              omissions of any service provider, internet accessibility or
              availability or for traffic congestion or unauthorized human act,
              including any errors or mistakes.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              INDEMNIFICATION
            </Card.Subtitle>
            <Card.Text className="termsText">
              You agree to defend and indemnify LOGOPWA from and against any
              claims, causes of action, demands, recoveries, losses, damages,
              fines, penalties or other costs or expenses of any kind or nature
              including but not limited to reasonable legal and accounting fees,
              brought by third parties as a result of:<br></br>
              <br></br>• Your breach of this agreement or the documents
              referenced herein.<br></br>• Your violation of any law or the
              rights of a third party.<br></br>• Your use of the LOGOPWA
              services.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              CHANGES AND TERMINATION
            </Card.Subtitle>
            <Card.Text className="termsText">
              We may change the website and these terms at any time, in our sole
              discretion and without notice to you. You are responsible for
              remaining knowledgeable about these terms. Your continued use of
              the site constitutes your acceptance of any changes to these Terms
              and any changes will supersede all previous versions of the terms.
              Unless otherwise specified herein, all changes to these Terms
              apply to all users take effect. Furthermore, we may terminate this
              agreement with you under these Terms at any time by notifying you
              in writing (including by email) or without any warning.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              PERSONAL DATA
            </Card.Subtitle>
            <Card.Text className="termsText">
              Any personal information you post on or otherwise submit in
              connection with the Services and the use of the platform, will be
              used in accordance with our Privacy Policy. Please refer to our
              Privacy Policy.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">ASSIGNMENT</Card.Subtitle>
            <Card.Text className="termsText">
              This agreement and any rights and licenses granted hereunder, may
              not be transferred or assigned by you, but may be assigned by
              LOGOPWA without restriction.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              INTEGRATION CLAUSE
            </Card.Subtitle>
            <Card.Text className="termsText">
              This agreement and any rights and licenses granted hereunder, may
              not be transferred or assigned by you, but may be assigned by
              LOGOPWA without restriction.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">DISPUTES</Card.Subtitle>
            <Card.Text className="termsText">
              You agree that any dispute, claim or controversy arising out of or
              relating to these Terms or the breach, termination, enforcement,
              interpretation or validity thereof or the use of the LOGOPWA Inc
              website and services, may be resolved through arbitration that
              will be regulated under the rules of the American Arbitration
              Association in Orlando, Orange County, Florida. You understand
              that you voluntarily and without restrictions waive certain
              rights, including the right to proceed with an action in court,
              the right to a jury trial, a trial with a judge, the right to an
              appeal, the right to a wide discovery. And I accept that the
              arbitration or an arbitrator will be the one who decides a case,
              not a jury or a judge. Arbitration will be the only means to
              resolve, mediate for the purpose of resolving any dispute,
              controversy or claim of a judicial process or any other means, for
              which you waive your rights.<br></br>
              <br></br>
              If you do not wish to use our services, please do not access our
              platform or register on our site so as not to be compromised or
              obligated to our services.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">HEADINGS</Card.Subtitle>
            <Card.Text className="termsText">
              Headings are for reference purposes only and in no way define,
              limit, construe or describe the scope or extent of such section.
              Our failure to act with respect to a breach by you or others does
              not waive our right to act with respect to subsequent or similar
              breaches. These terms set forth the entire understanding and
              agreement between us with respect to the subject matter therein.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">
              FINAL PROVISIONS
            </Card.Subtitle>
            <Card.Text className="termsText">
              These Terms are governed by the USA law. Use of our website is
              unauthorized in any jurisdiction that does not give effect to all
              provisions of these Terms, including, without limitation, this
              paragraph.<br></br>
              <br></br>
              Our performance of these Terms is subject to existing laws and
              legal process, and nothing contained in these Terms limits our
              right to comply with law enforcement or other governmental or
              legal requests or requirements relating to your use of our
              Platform or information provided to or gathered by us with respect
              to such use.<br></br>
              <br></br>
              If any part of these Terms is found to be invalid, illegal or
              unenforceable, the validity, legality and enforceability of the
              remaining provisions will not in any way be affected or impaired.
              Our failure or delay in enforcing any provision of these Terms at
              any time does not waive our right to enforce the same or any other
              provision(s) hereof in the future.<br></br>
              <br></br>
              Any rights not expressly granted herein are reserved.
            </Card.Text>
            <Card.Subtitle className="termsSubTitle">CONTACT US</Card.Subtitle>
            <Card.Text className="termsText">
              If you have questions or concerns about these terms, please
              contact us at:<br></br>
              info@logopwa.com<br></br>

            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </Layout>
  )
}



// <Card.Subtitle className="termsSubTitle">SERVICES</Card.Subtitle>
//               <Card.Text className="termsText">
//                 LOGOPWA offers the following packages:
//               </Card.Text>
//               <Card.Text className="termsText">Logo from $199</Card.Text>
//               <Card.Text className="termsText">
//               Let a logo designer take care of your ideal logo. Our goal is to
//               design a logo that is simple with quality and originality.
//               </Card.Text>
//             <ListGroup variant="flush">
//               <ListGroup.Item>• 2 logo design concepts</ListGroup.Item>
//               <ListGroup.Item>• 1 designer</ListGroup.Item>
//               <ListGroup.Item>• 3 rounds of revisions</ListGroup.Item>
//               <ListGroup.Item>• Fonts and color codes</ListGroup.Item>
//               <ListGroup.Item>
//                 • Source files - transparent background on PNG, JPG with vector
//                 files on EPS and AI
//               </ListGroup.Item>
//               <ListGroup.Item>
//                 • Get logo concepts in 3 days for revision or approval
//               </ListGroup.Item>
//             </ListGroup>
//             <Card.Subtitle className="termsSubTitle">
//               Website starting from $999
//             </Card.Subtitle>
//               <Card.Text className="termsText">text</Card.Text>




// export const query = graphql`
//          {
//            allContentfulTermsAndConditions {
//              nodes {
//                legals {
//                  id
//                  legals
//                }
//              }
//            }
//          }
//        `

// const terms  = ({ data }) => {
//     const {
// allContentfulTermsAndConditions: {nodes: policies },
//     } = data
//     console.log("before")
//     console.log(policies)
//     return (
//         <div>
//             <h1>Terms and Conditions</h1>
//             {policies.map(policy => {
//                 console.log(`after`)
//                 console.log(policy)
//                 console.log('terms');
//                 console.log(policy.legals.id);
//                 return <article key={policy.legals.id}>{policy.legals.legals}</article>
//             })}
//         </div>
//     )
// }


// export default terms;
